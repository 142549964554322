<template>
  <div class="main">
    <div class="main-case">
      <div class="main-case-box">
        <span class="main-case-box-title">VllShop 卖家真实案例</span>
        <span class="main-case-box-subtitle">建站如此简单</span>
      </div>
    </div>
    <div class="center-box">
      <div class="main-box">
        <card :mock="false" v-for="(item,index) in showCaseList" :id="'a'+index" :key="'a'+index" class="imgCard" :src="item.img" />
        <!-- 用于一个占位 -->
        <div v-if="(showCaseList.length+1)%2 == 0" class="show-card" />
      </div>
    </div>
    <div class="main-otherbox">
      <div class="main-otherbox-caselabe">
        <span
          style="
            font-size: 16px;
            font-weight: 400;
            color: #2c2c2c;
            margin-right: 34px;
          "
        >行业：</span>
        <!-- :casebtn-choose -->
        <el-button v-for="(item,index) in btnList" :key="'bl'+index" class="casebtn" :class="currentIndex===index?'casebtn-choose':''">{{ item }}</el-button>
      </div>
      <div class="center-box">
        <div class="main-box">
          <card :mock="false" v-for="(item,index) in caseList" :id="'m'+index" :key="'m'+index" class="imgCard" :src="item.img" />
          <!-- 用于一个占位 -->
          <div v-if="(caseList.length)%2 == 0" class="show-card" />
        </div>
      </div>
    </div>
    <case-footer-banner></case-footer-banner>
  </div>
</template>

<script>
import card from '@/components/ImgCard'
import CaseFooterBanner from '@/layout/footer/caseFooterBanner'

export default {
  components: {
    CaseFooterBanner,
    // BannerStation,
    card
  },
  data() {
    return {
      currentIndex: 0,
      btnList: [
        '全部'
        // '美妆家居',
        // '衣着精品',
        // '3C休闲'
      ],
      showCaseList: [
        {
          img: require('../../assets/img/model/case/1.png'),
          name: 'PANDORA'
        },
        {
          img: require('../../assets/img/model/case/2.png'),
          name: 'Martin'
        },
        {
          img: require('../../assets/img/model/case/3.png'),
          name: 'Martin'
        }
      ],
      caseList: [
        {
          img: require('../../assets/img/model/case/1.png'),
          name: 'Table'
        },
        {
          img: require('../../assets/img/model/case/2.png'),
          name: 'CandyStore'
        },
        {
          img: require('../../assets/img/model/case/3.png'),
          name: 'Martin'
        },
        {
          img: require('../../assets/img/model/case/4.png'),
          name: 'PANDORA'
        },
        {
          img: require('../../assets/img/model/case/5.png'),
          name: 'FARFETCH'
        },
        {
          img: require('../../assets/img/model/case/6.png'),
          name: 'Car'
        },
        {
          img: require('../../assets/img/model/case/7.png'),
          name: 'CarV'
        },
        {
          img: require('../../assets/img/model/case/8.png'),
          name: 'CarA'
        },
        {
          img: require('../../assets/img/model/case/9.png'),
          name: 'CarN'
        }
      ]
    }
  },
  methods: {
    setList(index) {
      this.currentIndex = index
      if (index === 0) {
        this.caseList = this.allList
      }
      if (index === 1) {
        this.caseList = this.beautyList
      }
      if (index === 2) {
        this.caseList = this.clothingList
      }
      if (index === 3) {
        this.caseList = this.threeCList
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.center-box{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.main-box {
  padding: 0px 50px;
  margin-bottom: 50px;
  max-width: 100vw;
  width: 1200px;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
}
.imgCard{
  margin: 50px 20px 0;
}
.show-card{
  width: 350px !important;
}

::v-deep .el-button:hover{
    color: #000;
    background: rgb(190, 190, 190);
}
.show-card{
    width: 400px;
}
// 处理按钮
.casebtn {
  border: unset;
  cursor: pointer;
  font-weight: 400;
  border-radius: 16px;
  padding: 0 20px;
  height: 32px;
  line-height: 32px;
}
.casebtn-choose{
  color: white;
  background-color: #2c4bfd;
}
.casebtn-choose:focus{
  color: white;
  background-color: #2c4bfd;
}
.casebtn + .casebtn {
  margin-left: 60px;
}
.casebtn:hover{
    // background-color: rgb(230, 228, 228);
}
.main {
  &-case {
    display: flex;
    align-items: center;
    width: 100%;
    height: 232px;
    &-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-title {
        font-size: 55px;
        font-weight: 700;
        color: #2c2c2c;
      }
      &-subtitle {
        font-size: 20px;
        font-weight: 400;
        color: #999;
        margin-top: 25px;
      }
    }
  }
  // 分割线
  &-otherbox {
    // background-color: rgb(236, 212, 105);
    &-caselabe {
      max-width: 1000px;
      margin: 90px auto;
      text-align: left;
      overflow: hidden;
      @media (max-width: 1136px) {
        padding-left: 120px;
      }
    }
    &-casebox {
      display: flex;
      flex-flow: wrap;
      justify-content: space-between;
      &-row {
        width: 400px;
        margin-bottom: 45px;
        &-img {
          width: 400px;
          height: 250px;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
