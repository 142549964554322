/**
* @author  XuHongli
* @date  2022/2/17 13:58
* @version 1.0
* @description 案例页面 卡片图片
*/

<template>
  <div>
    <div
      class="main-box-card"
      @mouseenter="mouseenter(id)"
      @mouseleave="mouseleave(id)"
    >
      <div class="main-box-card-onebox">
        <span class="main-box-card-onebox-span" />
        <span class="main-box-card-onebox-span" />
        <span class="main-box-card-onebox-span" />
      </div>
      <div :class="isImgMock&&mock?'mack':''">
        <el-button v-show="isImgMock" type="primary" @click="leave" v-if="mock">
          选择
        </el-button>
      </div>
      <div style="position: relative;">
        <img
          :id="id"
          :src="src"
          alt="模板图片"
          class="main-box-card-img"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mock: {
      type: Boolean,
      default: true
    },
    id: {
      type: String,
      required: true
    },
    // 上滑速度
    slidingSpeed: {
      type: Number,
      default: 1
    },
    // 下滑速度
    glidingSpeed: {
      type: Number,
      default: 5
    },
    src: {
      type: String,
      required: true,
      default: require('../../assets/img/model/oneModelPic.png')
    }
  },
  data() {
    return {
      isImgMock: false,
      topNum: 0,
      downNum: 0,
      start: '',
      end: ''
    }
  },
  mounted() {
  },
  methods: {
    leave() {
      clearInterval(this.start)
      clearInterval(this.end)
      this.$router.push({ name: 'Register', query: { registerType: 'login' }})
    },
    mouseenter(id) {
      const that = this
      that.isImgMock = true
      that.topNum = 0
      clearInterval(that.start)
      clearInterval(that.end)
      that.start = setInterval(function() {
        that.topNum += -that.slidingSpeed
        document.getElementById(id).style.top = that.topNum + 'px'
        if (Math.abs(that.topNum) === (document.getElementById(id).offsetHeight - 467)) {
          clearInterval(that.start)
        }
      }, 10)
    },
    mouseleave(id) {
      const that = this
      that.isImgMock = false
      that.downNum = that.topNum // 赋值向上偏移量
      clearInterval(this.start) // 清除向上的移动循环
      that.end = setInterval(function() {
        that.downNum += that.glidingSpeed // 值相等,防止撕裂
        document.getElementById(id).style.top = that.downNum + 'px'
        if (Number(document.getElementById(id).style.top.replace('px', '')) >= -that.glidingSpeed) { // 值相等,防止撕裂
          clearInterval(that.end)
        }
      }, 10)
    }
  }
}
</script>

<style lang="scss" scoped>
.main-box {
//   background: rgb(225, 243, 124);
  &-card {
    position: relative;
    width: 300px;
    height: 467px;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    // background: rgb(225, 243, 124);
    box-shadow: 0 0 7px #aaa;
    overflow: hidden;
    &-onebox {
      position: relative;
      top: 0px;
      width: 100%;
      height: 18px;
      z-index: 99;
      line-height: 18px;
      text-align: left;
      padding: 0 10px;
      background-color: #b9cbd7;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      &-span {
        margin-right: 5px;
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #fff;
        vertical-align: middle;
      }
    }
    &-img {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: auto;
      max-height: unset;
    }
  }
}
.mack{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color:rgba($color: #000000, $alpha: .5);
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
